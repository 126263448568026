import { toString as toString_1, Union } from "../fable_modules/fable-library.3.7.22/Types.js";
import { UserAlerts$reflection, Alert$reflection } from "../Shared/ApiDataTypes.js";
import { unit_type, equals, obj_type, union_type } from "../fable_modules/fable-library.3.7.22/Reflection.js";
import { useReact_useEffectOnce_3A5B6456, useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.64.0/React.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise } from "../fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Auto_generateBoxedDecoderCached_79988AEF, Auto_generateBoxedDecoder_79988AEF } from "../fable_modules/Thoth.Json.7.0.0/Decode.fs.js";
import { PromiseBuilder__Delay_62FBFDE1 as PromiseBuilder__Delay_62FBFDE1_1, PromiseBuilder__Run_212F1D4B as PromiseBuilder__Run_212F1D4B_1 } from "../fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise as promise_1 } from "../fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { FetchError } from "../fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.3.7.22/Choice.js";
import { Helper_fetch, Helper_withContentTypeJson, Helper_withProperties } from "../fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { singleton as singleton_1, cons, ofArray, empty } from "../fable_modules/fable-library.3.7.22/List.js";
import { Types_RequestProperties } from "../fable_modules/Fable.Fetch.2.1.0/Fetch.fs.js";
import { keyValueList } from "../fable_modules/fable-library.3.7.22/MapUtil.js";
import { map, defaultArg } from "../fable_modules/fable-library.3.7.22/Option.js";
import { toString } from "../fable_modules/Thoth.Json.7.0.0/Encode.fs.js";
import { Auto_generateBoxedEncoderCached_Z20B7B430 } from "../fable_modules/Thoth.Json.7.0.0/Encode.fs.js";
import { fromString } from "../fable_modules/Thoth.Json.7.0.0/Decode.fs.js";
import { createObj, uncurry } from "../fable_modules/fable-library.3.7.22/Util.js";
import { sortByDescending, map as map_1, append } from "../fable_modules/fable-library.3.7.22/Array.js";
import { MuiHelpers_createElement } from "../fable_modules/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import TableCell from "@material-ui/core/TableCell";
import { createElement } from "react";
import { singleton, append as append_1, delay, toList } from "../fable_modules/fable-library.3.7.22/Seq.js";
import { loadingIndicatorSmall, snackError } from "../ViewHelpers.js";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import { Interop_reactApi } from "../fable_modules/Feliz.1.64.0/Interop.fs.js";
import TableHead from "@material-ui/core/TableHead";
import { utcDateToLocalDateTime } from "../Utils.js";
import { compare, parse } from "../fable_modules/fable-library.3.7.22/Date.js";
import TableBody from "@material-ui/core/TableBody";

export class AlertEntry extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["InsiderTrade", "RSI", "Short"];
    }
    toString() {
        const this$ = this;
        switch (this$.tag) {
            case 1: {
                return "RSI";
            }
            case 2: {
                return "Short";
            }
            default: {
                return "Insider Trade";
            }
        }
    }
}

export function AlertEntry$reflection() {
    return union_type("UserAlerts.AlertEntry", [], AlertEntry, () => [[["Item", Alert$reflection()]], [["Item", Alert$reflection()]], [["Item", Alert$reflection()]]]);
}

export function AlertEntry__Registered(this$) {
    switch (this$.tag) {
        case 1: {
            return this$.fields[0].Registered;
        }
        case 2: {
            return this$.fields[0].Registered;
        }
        default: {
            return this$.fields[0].Registered;
        }
    }
}

export function AlertEntry__Asset(this$) {
    switch (this$.tag) {
        case 1: {
            return this$.fields[0].Asset;
        }
        case 2: {
            return this$.fields[0].Asset;
        }
        default: {
            return this$.fields[0].Asset;
        }
    }
}

export function Alerts(alertsInputProps) {
    let elems_6;
    const userId = alertsInputProps.userId;
    const patternInput = useFeliz_React__React_useState_Static_1505(void 0);
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(void 0);
    const setErrMsg = patternInput_1[1];
    useReact_useEffectOnce_3A5B6456(() => {
        const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            let url, decoder, data_1, caseStrategy_2, extra_2;
            return ((url = (`/api/user/${userId}/alerts`), (decoder = Auto_generateBoxedDecoder_79988AEF(UserAlerts$reflection(), void 0, void 0), (data_1 = (void 0), (caseStrategy_2 = (void 0), (extra_2 = (void 0), (() => {
                let properties_2, headers_2;
                try {
                    const properties_3 = Helper_withProperties(empty(), (properties_2 = ofArray([new Types_RequestProperties(0, "GET"), (headers_2 = Helper_withContentTypeJson(data_1, empty()), new Types_RequestProperties(1, keyValueList(headers_2, 0)))]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_2, extra_2)(data_1_1))), properties_2), data_1), properties_2)));
                    const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(url, properties_3).then((_arg_1) => {
                        let response_1, decoder_1_1;
                        return ((response_1 = _arg_1, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_79988AEF(UserAlerts$reflection(), caseStrategy_2, extra_2)), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_2) => {
                            let matchValue;
                            return Promise.resolve(equals(UserAlerts$reflection(), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg_2), (matchValue.tag === 1) ? (new FSharpResult$2(1, new FetchError(1, matchValue.fields[0]))) : (new FSharpResult$2(0, matchValue.fields[0])))));
                        })))) : (Promise.resolve(new FSharpResult$2(1, new FetchError(2, response_1))))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                    }))));
                    return pr.then(void 0, ((arg_3) => (new FSharpResult$2(1, new FetchError(3, arg_3)))));
                }
                catch (exn) {
                    return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, new FetchError(0, exn))))));
                }
            })())))))).then((_arg_3) => {
                const res = _arg_3;
                if (res.tag === 1) {
                    setErrMsg("Could not fetch user alerts");
                    return Promise.resolve();
                }
                else {
                    const x_1 = res.fields[0];
                    patternInput[1](append(append(map_1((arg_4) => (new AlertEntry(0, arg_4)), x_1.InsiderTrades), map_1((arg_5) => (new AlertEntry(1, arg_5)), x_1.RSI)), map_1((arg_6) => (new AlertEntry(2, arg_6)), x_1.Short)));
                    return Promise.resolve();
                }
            });
        }));
        pr_1.then();
    });
    const th$0027 = (x_2) => MuiHelpers_createElement(TableCell, [["size", "small"], ["variant", "head"], ["children", x_2]]);
    const td$0027 = (x_3) => MuiHelpers_createElement(TableCell, [["size", "small"], ["variant", "body"], ["children", x_3]]);
    return createElement("div", createObj(ofArray([["style", {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: 2 + "vw",
        paddingTop: 0,
        gap: 30 + "px ",
    }], (elems_6 = toList(delay(() => append_1(singleton(snackError(patternInput_1[0], () => {
        setErrMsg(void 0);
    })), delay(() => append_1(singleton(MuiHelpers_createElement(Typography, [["variant", "h6"], ["children", "Active alerts"]])), delay(() => {
        let elems_5, elements_4, children_1, children, children_3;
        const matchValue_1 = patternInput[0];
        if (matchValue_1 != null) {
            const x_4 = matchValue_1;
            return (x_4.length === 0) ? singleton(createElement("span", {
                children: ["No active alerts"],
            })) : singleton(createElement("div", createObj(ofArray([["style", {
                display: "flex",
                flexDirection: "column",
                maxWidth: 600,
                width: 100 + "%",
            }], (elems_5 = [MuiHelpers_createElement(Table, [["size", "small"], (elements_4 = [(children_1 = singleton_1((children = ofArray([th$0027("Type"), th$0027("Asset"), th$0027("Registered")]), MuiHelpers_createElement(TableRow, [["children", Interop_reactApi.Children.toArray(Array.from(children))]]))), MuiHelpers_createElement(TableHead, [["children", Interop_reactApi.Children.toArray(Array.from(children_1))]])), (children_3 = map_1((a_2) => {
                const children_2 = ofArray([td$0027(toString_1(a_2)), td$0027(AlertEntry__Asset(a_2)), td$0027(utcDateToLocalDateTime(AlertEntry__Registered(a_2)))]);
                return MuiHelpers_createElement(TableRow, [["children", Interop_reactApi.Children.toArray(Array.from(children_2))]]);
            }, sortByDescending((a_1) => parse(AlertEntry__Registered(a_1)), x_4, {
                Compare: compare,
            })), MuiHelpers_createElement(TableBody, [["children", Interop_reactApi.Children.toArray(Array.from(children_3))]]))], ["children", Interop_reactApi.Children.toArray(Array.from(elements_4))])])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])]))));
        }
        else {
            return singleton(loadingIndicatorSmall());
        }
    })))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])])));
}

