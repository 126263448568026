import { toString as toString_1, Union } from "../fable_modules/fable-library.3.7.22/Types.js";
import { unit_type, equals, obj_type, array_type, union_type } from "../fable_modules/fable-library.3.7.22/Reflection.js";
import { useReact_useEffectOnce_3A5B6456, useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.64.0/React.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise } from "../fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Auto_generateBoxedDecoderCached_79988AEF, Auto_generateBoxedDecoder_79988AEF } from "../fable_modules/Thoth.Json.7.0.0/Decode.fs.js";
import { NotificationLog$reflection } from "../Shared/ApiDataTypes.js";
import { PromiseBuilder__Delay_62FBFDE1 as PromiseBuilder__Delay_62FBFDE1_1, PromiseBuilder__Run_212F1D4B as PromiseBuilder__Run_212F1D4B_1 } from "../fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise as promise_1 } from "../fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { FetchError } from "../fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.3.7.22/Choice.js";
import { Helper_fetch, Helper_withContentTypeJson, Helper_withProperties } from "../fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { singleton as singleton_1, cons, ofArray, empty } from "../fable_modules/fable-library.3.7.22/List.js";
import { Types_RequestProperties } from "../fable_modules/Fable.Fetch.2.1.0/Fetch.fs.js";
import { keyValueList } from "../fable_modules/fable-library.3.7.22/MapUtil.js";
import { map, defaultArg } from "../fable_modules/fable-library.3.7.22/Option.js";
import { toString } from "../fable_modules/Thoth.Json.7.0.0/Encode.fs.js";
import { Auto_generateBoxedEncoderCached_Z20B7B430 } from "../fable_modules/Thoth.Json.7.0.0/Encode.fs.js";
import { fromString } from "../fable_modules/Thoth.Json.7.0.0/Decode.fs.js";
import { createObj, uncurry } from "../fable_modules/fable-library.3.7.22/Util.js";
import { MuiHelpers_createElement } from "../fable_modules/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import TableCell from "@material-ui/core/TableCell";
import { createElement } from "react";
import { empty as empty_1, singleton, append, delay, toList } from "../fable_modules/fable-library.3.7.22/Seq.js";
import { loadingIndicatorSmall, snackError } from "../ViewHelpers.js";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogContent from "@material-ui/core/DialogContent";
import { Interop_reactApi } from "../fable_modules/Feliz.1.64.0/Interop.fs.js";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import { sortByDescending, map as map_1 } from "../fable_modules/fable-library.3.7.22/Array.js";
import { utcDateToLocalDateTime } from "../Utils.js";
import { compare, parse } from "../fable_modules/fable-library.3.7.22/Date.js";
import TableBody from "@material-ui/core/TableBody";

export class PushReason extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["InsiderTrade", "Short", "ReviewComment", "ReviewReply", "AssetPostComment", "AssetPostReply", "LikeOne", "LikeFive", "LikeTen", "LikeTwentyFive", "LikeFifthy", "LikeHundred", "RSI", "Test", "Unmapped"];
    }
    toString() {
        const this$ = this;
        switch (this$.tag) {
            case 1: {
                return "Short";
            }
            case 2: {
                return "Review comment";
            }
            case 3: {
                return "Review reply";
            }
            case 4: {
                return "Post comment";
            }
            case 5: {
                return "Post reply";
            }
            case 6: {
                return "First like";
            }
            case 7: {
                return "Fifth like";
            }
            case 8: {
                return "Tenth like";
            }
            case 9: {
                return "Twenthieth like";
            }
            case 10: {
                return "Fiftieth like";
            }
            case 11: {
                return "Hundreth like";
            }
            case 12: {
                return "RSI";
            }
            case 13: {
                return "Test";
            }
            case 14: {
                return "Unmapped";
            }
            default: {
                return "Insider trade";
            }
        }
    }
}

export function PushReason$reflection() {
    return union_type("UserAlertLog.PushReason", [], PushReason, () => [[], [], [], [], [], [], [], [], [], [], [], [], [], [], []]);
}

export function PushReason_FromId_Z524259A4(x) {
    switch (x) {
        case 1: {
            return new PushReason(0);
        }
        case 2: {
            return new PushReason(1);
        }
        case 3: {
            return new PushReason(2);
        }
        case 4: {
            return new PushReason(3);
        }
        case 5: {
            return new PushReason(4);
        }
        case 6: {
            return new PushReason(5);
        }
        case 13: {
            return new PushReason(12);
        }
        case 100: {
            return new PushReason(13);
        }
        default: {
            return new PushReason(14);
        }
    }
}

export function AlertLog(alertLogInputProps) {
    let elems_10;
    const userId = alertLogInputProps.userId;
    const patternInput = useFeliz_React__React_useState_Static_1505(void 0);
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(void 0);
    const setErrMsg = patternInput_1[1];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(void 0);
    const setViewContent = patternInput_2[1];
    useReact_useEffectOnce_3A5B6456(() => {
        const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            let url, decoder, data_1, caseStrategy_2, extra_2;
            return ((url = (`/api/user/${userId}/sent-alerts`), (decoder = Auto_generateBoxedDecoder_79988AEF(array_type(NotificationLog$reflection()), void 0, void 0), (data_1 = (void 0), (caseStrategy_2 = (void 0), (extra_2 = (void 0), (() => {
                let properties_2, headers_2;
                try {
                    const properties_3 = Helper_withProperties(empty(), (properties_2 = ofArray([new Types_RequestProperties(0, "GET"), (headers_2 = Helper_withContentTypeJson(data_1, empty()), new Types_RequestProperties(1, keyValueList(headers_2, 0)))]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(obj_type, caseStrategy_2, extra_2)(data_1_1))), properties_2), data_1), properties_2)));
                    const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(url, properties_3).then((_arg_1) => {
                        let response_1, decoder_1_1;
                        return ((response_1 = _arg_1, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_79988AEF(array_type(NotificationLog$reflection()), caseStrategy_2, extra_2)), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_2) => {
                            let matchValue;
                            return Promise.resolve(equals(array_type(NotificationLog$reflection()), unit_type) ? (new FSharpResult$2(0, void 0)) : ((matchValue = fromString(uncurry(2, decoder_1_1), _arg_2), (matchValue.tag === 1) ? (new FSharpResult$2(1, new FetchError(1, matchValue.fields[0]))) : (new FSharpResult$2(0, matchValue.fields[0])))));
                        })))) : (Promise.resolve(new FSharpResult$2(1, new FetchError(2, response_1))))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                    }))));
                    return pr.then(void 0, ((arg_3) => (new FSharpResult$2(1, new FetchError(3, arg_3)))));
                }
                catch (exn) {
                    return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, new FetchError(0, exn))))));
                }
            })())))))).then((_arg_3) => {
                const res = _arg_3;
                if (res.tag === 1) {
                    setErrMsg("Could not fetch user alert history");
                    return Promise.resolve();
                }
                else {
                    patternInput[1](res.fields[0]);
                    return Promise.resolve();
                }
            });
        }));
        pr_1.then();
    });
    const th$0027 = (x_2) => MuiHelpers_createElement(TableCell, [["size", "small"], ["variant", "head"], ["children", x_2]]);
    const td$0027 = (x_3) => MuiHelpers_createElement(TableCell, [["size", "small"], ["variant", "body"], ["children", x_3]]);
    return createElement("div", createObj(ofArray([["style", {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: 2 + "vw",
        paddingTop: 0,
        gap: 30 + "px ",
    }], (elems_10 = toList(delay(() => append(singleton(snackError(patternInput_1[0], () => {
        setErrMsg(void 0);
    })), delay(() => append(singleton(MuiHelpers_createElement(Typography, [["variant", "h6"], ["children", "Sent alerts"]])), delay(() => {
        let matchValue_1, elements_1, children;
        return append((matchValue_1 = patternInput_2[0], (matchValue_1 != null) ? singleton(MuiHelpers_createElement(Dialog, [["open", true], ["onClose", (_arg_6, v) => {
            setViewContent(void 0);
        }], (elements_1 = [(children = singleton_1(MuiHelpers_createElement(DialogContentText, [["children", matchValue_1]])), MuiHelpers_createElement(DialogContent, [["children", Interop_reactApi.Children.toArray(Array.from(children))]]))], ["children", Interop_reactApi.Children.toArray(Array.from(elements_1))])])) : ((empty_1()))), delay(() => {
            let elems_9, elements_7, children_2, children_1, children_4;
            const matchValue_2 = patternInput[0];
            if (matchValue_2 != null) {
                const x_4 = matchValue_2;
                return (x_4.length === 0) ? singleton(createElement("span", {
                    children: ["No alerts sent"],
                })) : singleton(createElement("div", createObj(ofArray([["style", {
                    display: "flex",
                    flexDirection: "column",
                    maxWidth: 600,
                    width: 100 + "%",
                }], (elems_9 = [MuiHelpers_createElement(Table, [["size", "small"], (elements_7 = [(children_2 = singleton_1((children_1 = ofArray([th$0027("OS"), th$0027("Reason"), th$0027("Sent"), th$0027("")]), MuiHelpers_createElement(TableRow, [["children", Interop_reactApi.Children.toArray(Array.from(children_1))]]))), MuiHelpers_createElement(TableHead, [["children", Interop_reactApi.Children.toArray(Array.from(children_2))]])), (children_4 = toList(delay(() => map_1((a_2) => {
                    let elements_4, elems_4;
                    const children_3 = ofArray([td$0027((a_2.OS === 1) ? "iOS" : "Android"), td$0027(toString_1(PushReason_FromId_Z524259A4(a_2.ReasonId))), td$0027(utcDateToLocalDateTime(a_2.Sent)), MuiHelpers_createElement(TableCell, [["size", "small"], ["variant", "body"], (elements_4 = [createElement("div", createObj(ofArray([["onClick", (_arg_7) => {
                        setViewContent(a_2.Content);
                    }], ["style", {
                        cursor: "pointer",
                    }], (elems_4 = [createElement("i", {
                        className: "fas fa-info",
                    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elements_4))])])]);
                    return MuiHelpers_createElement(TableRow, [["children", Interop_reactApi.Children.toArray(Array.from(children_3))]]);
                }, sortByDescending((a_1) => parse(a_1.Sent), x_4, {
                    Compare: compare,
                })))), MuiHelpers_createElement(TableBody, [["children", Interop_reactApi.Children.toArray(Array.from(children_4))]]))], ["children", Interop_reactApi.Children.toArray(Array.from(elements_7))])])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_9))])]))));
            }
            else {
                return singleton(loadingIndicatorSmall());
            }
        }));
    })))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_10))])])));
}

